const BHSearch = {
	BHSearch: {
		defaultProps: {
			priceProps: {
				showPercentage: false,
			},
		},
	},
}

export default BHSearch
