const Checkbox = {
	MuiCheckbox: {
		styleOverrides: {
			root: {
				color: 'var(--color-black)',
			},
			colorPrimary: {
				color: 'var(--color-black)',
			},
			colorSecondary: { color: 'var(--color-black)' },
		},
	},
}

export default Checkbox
