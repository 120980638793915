const Tabs = {
	MuiTabs: {
		defaultProps: {
			indicatorColor: 'primary',
		},
		styleOverrides: {
			scroller: {
				display: 'inline-flex',
				['@media (min-width: 1024px)']: {
					display: 'inline-block',
				},
			},
		},
	},
	MuiTab: {
		defaultProps: {
			disableRipple: true,
		},
		styleOverrides: {
			root: {
				color: 'var(--color-black)',
				fontSize: 16,
				minHeight: 22,
				maxWidth: 'none',
				minWidth: 'unset',
				textTransform: 'uppercase',
				fontFamily: 'var(--font-family-oracle-regular)',
				padding: 0,
				margin: '0 12px',
				'&:hover': {
					backgroundColor: 'rgba(237, 237, 237, 0.5)',
				},
				'&.Mui-selected': {
					fontFamily: 'var(--font-family-oracle-bold)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey-light)',
				},
				['@media (min-width: 1024px)']: {
					fontSize: 28,
					minHeight: 56,
					margin: '0 16px',
				},
			},
		},
	},
}

export default Tabs
