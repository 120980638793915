const Tooltip = {
	MuiTooltip: {
		styleOverrides: {
			tooltip: {
				fontSize: 12,
				fontFamily: 'var(--font-family-varela-round)',
			},
		},
	},
}

export default Tooltip
