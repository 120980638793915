const List = {
	MuiMenu: {
		styleOverrides: {
			list: {
				borderRadius: 'var(--input-border-radius)',
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				'&:first-of-type:not(:last-of-type)': {
					borderTopLeftRadius: 'var(--input-border-radius)',
					borderTopRightRadius: 'var(--input-border-radius)',
				},
				'&:last-of-type:not(:first-of-type)': {
					borderBottomLeftRadius: 'var(--input-border-radius)',
					borderBottomRightRadius: 'var(--input-border-radius)',
				},
			},
		},
	},
}

export default List
