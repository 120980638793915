import { fonts } from '../fonts'
import { themeColors } from '@/theme/colors'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			html: {
				fontFamily: fonts.varela,
				// fonts
				'--font-family-varela-round': fonts.varela,
				'--font-family-oracle-bold': fonts.oracle.bold,
				'--font-family-oracle-regular': fonts.oracle.regular,
				'--font-family': 'var(--font-family-varela-round)',
				'--font-family-alternative': 'var(--font-family-oracle-bold)',
				// colors
				'--color-white': '#ffffff',
				'--color-black': '#000000',
				'--color-blue': themeColors.blue,
				'--color-orange': themeColors.orange,
				'--color-purple': themeColors.purple,
				'--color-grey': themeColors.grey.main,
				'--color-grey-light': themeColors.grey.light,
				'--color-grey-medium': themeColors.grey.medium,
				'--color-grey-dark': themeColors.grey.dark,
				// shapes
				'--shape-border-radius': '12px',
				'--card-border-radius': '10px',
				'--input-border-radius': '23px',
			},
			body: {
				fontFamily: fonts.varela,
				fontWeight: 400,
			},
		},
	},
}

export default CssBaseline
