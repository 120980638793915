const Form = {
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				fontFamily: 'var(--font-family-varela-round)',
			},
		},
	},
}

export default Form
