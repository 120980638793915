import { alpha } from '@mui/system'
import { themeColors } from '../colors'
import { fonts } from '../fonts'

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				fontFamily: fonts.oracle.bold,
				fontWeight: 400,
				fontSize: 20,
				lineHeight: 1,
				textTransform: 'uppercase',
				padding: '8px 34px',
				minWidth: 'unset',
				minHeight: 57,
				color: 'var(--color-black)',
				'&:hover': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-black)',
				},
				'&:focus': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-black)',
				},
				'&:active': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-black)',
				},
				'&.Mui-disabled': {
					opacity: 0.2,
				},
				'&.MuiButton-sizeSmall': {
					fontSize: 16,
				},
				'> .MuiSvgIcon-root': {
					color: 'currentColor',
				},
			},
			contained: {
				borderRadius: 29,
				borderWidth: 3,
			},
			containedPrimary: {
				minWidth: 212,
				backgroundColor: 'var(--color-orange)',
				boxShadow: '0 2px 5px 0 #287c8f',
				color: 'var(--color-white)',
				borderColor: 'var(--color-orange)',
				'&:hover': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:active': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&.Mui-disabled': {
					borderColor: 'currentColor',
					backgroundColor: 'var(--color-white)',
				},
			},
			containedSecondary: {
				backgroundColor: 'var(--color-orange)',
				color: 'var(--color-white)',
				borderColor: 'var(--color-orange)',
				fontSize: 18,
				'&:hover': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:active': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&.Mui-disabled': {
					borderColor: 'var(--color-blue)',
					backgroundColor: 'var(--color-blue)',
				},
				'&.inverted': {
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-blue)',
					borderColor: 'var(--color-white)',
					'&:hover': {
						backgroundColor: 'var(--color-blue)',
						color: 'var(--color-white)',
						borderColor: 'var(--color-white)',
					},
					'&:focus': {
						backgroundColor: 'var(--color-blue)',
						color: 'var(--color-white)',
						borderColor: 'var(--color-white)',
					},
					'&:active': {
						backgroundColor: 'var(--color-blue)',
						color: 'var(--color-white)',
						borderColor: 'var(--color-white)',
					},
				},
			},
			containedTertiary: {
				fontSize: 24,
				backgroundColor: 'var(--color-blue)',
				color: 'var(--color-white)',
				boxShadow: '0 2px 5px 0 #287c8f',
				borderColor: 'var(--color-blue)',
				padding: '8px 34px',
				minHeight: 57,
				'&:hover': {
					backgroundColor: 'var(--color-blue)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-blue)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:active': {
					backgroundColor: 'var(--color-blue)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&.Mui-disabled': {
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-blue)',
					borderColor: 'var(--color-white)',
					opacity: 0.2,
				},
			},
			containedSizeLarge: {
				fontSize: 24,
				backgroundColor: 'var(--color-orange)',
				color: 'var(--color-white)',
				boxShadow: '0 2px 5px 0 #287c8f',
				borderColor: 'var(--color-orange)',
				padding: '8px 34px',
				minHeight: 57,
				'&:hover': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:active': {
					backgroundColor: 'var(--color-orange)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&.Mui-disabled': {
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-blue)',
					borderColor: 'var(--color-white)',
					opacity: 0.2,
				},
			},
			containedSizeSmall: {
				textTransform: 'unset',
			},
			outlined: {
				borderRadius: 15,
				borderWidth: 2,
				borderColor: 'currentColor',
				padding: '11px 18px',
				fontSize: 22,
				textTransform: 'unset',
				'&:hover': {
					borderWidth: 2,
				},
			},
			outlinedPrimary: {
				'&:hover': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-black)',
					backgroundColor: 'var(--color-orange)',
				},
				'&:focus': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-black)',
					backgroundColor: 'var(--color-white)',
				},
				'&:active': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-black)',
					backgroundColor: 'var(--color-white)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-black)',
					backgroundColor: 'var(--color-white)',
				},
			},
			outlinedSecondary: {
				color: 'var(--color-white)',
				borderColor: 'var(--color-white)',
				borderWidth: 3,
				'&:hover': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-white)',
					backgroundColor: 'var(--color-white)',
					borderWidth: 3,
				},
				'&:focus': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-white)',
					backgroundColor: 'var(--color-white)',
					borderWidth: 3,
				},
				'&:active': {
					color: 'var(--color-black)',
					borderColor: 'var(--color-white)',
					backgroundColor: 'var(--color-white)',
					borderWidth: 3,
				},
				'&.Mui-disabled': {
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
					opacity: 0.2,
				},
			},
			text: {
				textTransform: 'unset',
				minHeight: 'unset',
				fontSize: 22,
				borderWidth: 2,
				'&:hover': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
				'&:focus': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
				'&:active': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
				'&.Mui-disabled': {
					color: 'var(--color-black)',
				},
			},
			textPrimary: {
				'&:hover': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
				'&:focus': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
				'&:active': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
			},
			textSecondary: {
				'&:hover': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
				'&:focus': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
				'&:active': {
					color: alpha(themeColors.black, 0.2),
					borderColor: alpha(themeColors.black, 0.2),
				},
			},
			buttonAlternative: {
				fontFamily: fonts.oracle.bold,
				fontWeight: 400,
				lineHeight: 1,
				textTransform: 'uppercase',
				minWidth: 260,
				borderRadius: 29,
				borderWidth: 3,
				fontSize: 24,
				backgroundColor: 'var(--color-white)',
				color: 'var(--color-black)',
				borderColor: 'var(--color-white)',
				minHeight: 57,
				'&:hover': {
					backgroundColor: 'var(--color-blue)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-blue)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&:active': {
					backgroundColor: 'var(--color-blue)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-white)',
				},
				'&.Mui-disabled': {
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-blue)',
					borderColor: 'var(--color-blue)',
					opacity: 0.2,
				},
			},
		},
	},
}

export default Button
