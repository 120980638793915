import ArrowheadDownIcon from '@bluheadless/ui/src/atoms/icons/arrowhead-down-icon'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'

const Input = {
	MuiFilledInput: {
		styleOverrides: {
			colorWhite: {
				borderRadius: 'var(--input-border-radius)',
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				borderRadius: 'var(--input-border-radius)',
				'&.Mui-focused': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderWidth: '1px',
						borderColor: 'var(--color-black)',
					},
				},
			},
		},
	},
	MuiAutocomplete: {
		defaultProps: {
			popupIcon: <ArrowheadDownIcon />,
			PaperComponent: (props) => (
				<Grow in>
					<Paper {...props} />
				</Grow>
			),
		},
		styleOverrides: {
			listbox: {
				marginTop: 0,
			},
			paper: {
				borderTop: 0,
				borderRadius: 'var(--input-border-radius)',
				marginTop: 6,
				position: 'relative',
				':has(> *)': {
					// https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
					border: '2px solid var(--color-grey-light)',
					borderTop: 0,
				},
			},
		},
	},
}
export default Input
