const Alert = {
	MuiAlert: {
		styleOverrides: {
			root: {
				borderRadius: 'var(--shape-border-radius)',
			},
		},
	},
}

export default Alert
