const Container = {
	MuiContainer: {
		styleOverrides: {
			root: {
				paddingLeft: 16,
				paddingRight: 16,
				'@media (min-width: 1024px)': {
					'&:not(.MuiContainer-disableGutters)': {
						paddingLeft: 44,
						paddingRight: 44,
					},
				},
			},
		},
	},
}

export default Container
