import { themeColors } from '@/theme/colors'
import Alert from '@/theme/components/alert'
import BHBreadcrumbs from '@/theme/components/bh-breadcrumbs'
import BHSearch from '@/theme/components/bh-search'
import Button from '@/theme/components/button'
import Container from '@/theme/components/container'
import CssBaseline from '@/theme/components/css-baseline'
import Checkbox from '@/theme/components/form/checkbox'
import Form from '@/theme/components/form/form'
import Input from '@/theme/components/form/input'
import Select from '@/theme/components/form/select'
import Link from '@/theme/components/link'
import List from '@/theme/components/list'
import Tabs from '@/theme/components/tabs'
import Tooltip from '@/theme/components/tooltip'
import { fontSize, fonts, htmlFontSize } from '@/theme/fonts'
import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles'
import { createTheme } from '@mui/system'

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })
export const pxToRem = (px) => muiTheme.typography.pxToRem(px)

const fontFamily = fonts.varela
const fontFamilyAlternative = fonts.oracle.bold

const theme = createTheme(defaultTheme, {
	// Insert here project related theme config
	palette: {
		common: {
			black: themeColors.black,
			white: themeColors.white,
		},
		primary: {
			main: themeColors.black,
			light: themeColors.blue,
			dark: themeColors.blue,
			contrastText: themeColors.white,
		},
		secondary: {
			main: themeColors.blue,
			light: themeColors.blue,
			dark: themeColors.blue,
			contrastText: themeColors.white,
		},
		tertiary: {
			main: themeColors.orange,
			light: themeColors.orange,
			dark: themeColors.orange,
			contrastText: themeColors.blue,
		},
		grey: {
			main: themeColors.grey.main,
			light: themeColors.grey.light,
			medium: themeColors.grey.medium,
			dark: themeColors.grey.dark,
		},
		divider: '#DBDBDB',
		background: {
			paper: themeColors.white,
			default: themeColors.white,
			header: themeColors.white,
		},
		footer: {
			bgTop: themeColors.blue,
			bgMiddleTop: themeColors.blue,
			bgMiddle: themeColors.blue,
			bgMiddleBottom: themeColors.blue,
			bgBottom: themeColors.blue,
			border: themeColors.grey.soft,
			color: themeColors.white,
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(40),
			lineHeight: pxToRem(46),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(80),
				lineHeight: pxToRem(70),
			},
		},
		h2: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(32),
			lineHeight: pxToRem(38),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(40),
				lineHeight: pxToRem(46),
			},
		},
		h3: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(20),
			lineHeight: pxToRem(23),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(32),
				lineHeight: pxToRem(38),
			},
		},
		h4: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(18),
			lineHeight: pxToRem(20),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(20),
				lineHeight: pxToRem(23),
			},
		},
		h5: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(18),
			lineHeight: pxToRem(20),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(20),
				lineHeight: pxToRem(23),
			},
		},
		h6: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(18),
			lineHeight: pxToRem(20),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(20),
				lineHeight: pxToRem(23),
			},
		},
		body1: {
			fontFamily,
			fontWeight: 400,
			fontSize: pxToRem(16),
			lineHeight: pxToRem(24),
			letterSpacing: 0,
		},
		body2: {
			fontFamily,
			fontWeight: 400,
			fontSize: pxToRem(16),
			lineHeight: pxToRem(24),
			letterSpacing: 0,
		},
		p: {
			fontFamily,
			fontWeight: 400,
			fontSize: pxToRem(16),
			lineHeight: pxToRem(24),
			letterSpacing: 0,
		},
		subtitle1: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(16),
			lineHeight: pxToRem(20),
			letterSpacing: 0,
			textTransform: 'uppercase',
		},
		subtitle2: {
			fontFamily,
			fontWeight: 400,
			fontSize: pxToRem(12),
			lineHeight: pxToRem(24),
			letterSpacing: 0,
		},
		button: {
			fontFamily: fonts.oracle.bold,
			fontWeight: 400,
			fontSize: pxToRem(20),
			lineHeight: pxToRem(37),
			letterSpacing: 0,
		},
		caption: {
			fontFamily,
			fontWeight: 400,
			fontSize: pxToRem(12),
			lineHeight: 1,
			letterSpacing: 0,
		},
		headlinebig: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 700,
			fontSize: pxToRem(60),
			lineHeight: 1,
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(80),
			},
		},
		headlinemedium: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 700,
			fontSize: pxToRem(32),
			lineHeight: 1,
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(60),
			},
		},
		headlinesmall: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 700,
			fontSize: pxToRem(24),
			lineHeight: 1,
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: pxToRem(32),
			},
		},
		subheadline1: {
			fontFamily: fontFamilyAlternative,
			fontWeight: 400,
			fontSize: pxToRem(20),
			lineHeight: pxToRem(23),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: pxToRem(24),
				lineHeight: pxToRem(27),
			},
		},
		subheadline2: {
			fontFamily,
			fontWeight: 400,
			fontSize: pxToRem(12),
			lineHeight: pxToRem(24),
			letterSpacing: 0,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: pxToRem(16),
				lineHeight: pxToRem(24),
			},
		},
		checkbox: {
			fontFamily,
		},
		label: {
			fontFamily,
		},
		action: {
			active: themeColors.orange,
			hover: themeColors.orange,
			selected: themeColors.orange,
			disabledOpacity: 0.2,
		},
		text: {
			primary: themeColors.black,
			secondary: themeColors.blue,
			disabled: themeColors.black,
		},
	},
	shape: {
		borderRadius: 12,
	},
	textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
	scrollbarX: `
		overflow-x: auto;
		scrollbar-width: auto;
		scrollbar-height: 4px;
		scrollbar-color: #777777 #F7F7F7; // thumb, track

		::-webkit-scrollbar {
			height: 4px;
		}

		//track
		::-webkit-scrollbar-track {
			background: #F7F7F7; // grey.lighter
			border-radius: 3.5px;
		}

		//handle
		::-webkit-scrollbar-thumb {
			background: #777777; // grey.main
			border-radius: 3.5px;
		}

		//handle on hover
		::-webkit-scrollbar-thumb:hover {
			background: #373737; // grey.dark
		}
	`,
	components: {
		...CssBaseline,
		...Alert,
		...BHSearch,
		...BHBreadcrumbs,
		...Button,
		...Checkbox,
		...Container,
		...Form,
		...Input,
		...Link,
		...List,
		...Select,
		...Tabs,
		...Tooltip,
	},
})

export default theme
