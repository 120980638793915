export const themeColors = {
	white: '#fff',
	black: '#000',
	blue: '#1a9ebc',
	orange: '#ffc82f',
	purple: '#9252aa',
	grey: {
		light: '#DBDBDB',
		main: '#747474',
		medium: '#7E7E7E',
		dark: '#585858',
		soft: '#f8f8f8',
	},
}
