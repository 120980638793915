/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SearchIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M22.1 20.3l-4.2-4.2c1.2-1.5 1.8-3.4 1.8-5.3C19.7 6 15.8 2 11 2h-.1C6 2 2 5.9 2 10.7v.1c0 4.9 3.9 8.8 8.7 8.8h.1c1.9 0 3.8-.6 5.3-1.8l4.2 4.2 1.8-1.7zM4.5 10.8c0-3.5 2.7-6.3 6.2-6.3h.1c3.5 0 6.3 2.7 6.3 6.2v.1c0 3.5-2.7 6.3-6.2 6.3h-.1c-3.5 0-6.3-2.7-6.3-6.2v-.1z" />
	</svg>
))
SearchIconSvg.displayName = 'SearchIconSvg'

const SearchIcon = forwardRef((props, ref) => <Icon component={SearchIconSvg} ref={ref} {...props} />)
SearchIcon.displayName = 'SearchIcon'

SearchIcon.defaultProps = {
	...Icon.defaultProps,
}
SearchIcon.propTypes = {
	...Icon.propTypes,
}

export default SearchIcon
export { SearchIconSvg }
