const Select = {
	MuiSelect: {
		styleOverrides: {
			select: {
				borderRadius: 'var(--input-border-radius)',
			},
		},
	},
}

export default Select
